import ColorLensIcon from '@material-ui/icons/ColorLens';
import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import PubSub from 'pubsub-js'
import LensIcon from '@material-ui/icons/Lens';
import Tooltip from '@material-ui/core/Tooltip';
export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (color) => {
    PubSub.publish('lineColor', color);
    console.log(color)
    setAnchorEl(null);
  };

  return (
    <div>
      <Button style = {{color: "#ffffff"}}  aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
      <Tooltip title="Color Select">
      <ColorLensIcon/>
      </Tooltip>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        style={{"top": "3.5%"}}
        color = {"primary"}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem style = {{height:"20px"}} onClick={() => handleClose("blue")}><LensIcon style={{"width": "15px", "height": "15px"}} color={"primary"}/></MenuItem>
        <MenuItem style = {{height:"20px"}} onClick={() => handleClose("red")}><LensIcon style={{"width": "15px", "height": "15px"}} color={"secondary"}/></MenuItem>
        <MenuItem style = {{height:"20px"}} onClick={() => handleClose("green")}><LensIcon style={{"width": "15px", "height": "15px", "color": "green"}} /></MenuItem>
      </Menu>
    </div>
  )};