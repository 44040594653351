import React, { Component } from 'react';
import axios from 'axios';
import './VideoRoomComponent.css';
import { OpenVidu } from 'openvidu-browser';
import StreamComponent from './stream/StreamComponent';
import DialogExtensionComponent from './dialog-extension/DialogExtension';
import ChatComponent from './chat/ChatComponent';

import OpenViduLayout from '../layout/openvidu-layout';
import UserModel from '../models/user-model';
import ToolbarComponent from './toolbar/ToolbarComponent';
import Drawing  from './toolbar/pencil/Drawing';
import { Alert } from '@material-ui/lab';
var localUser = new UserModel();

class VideoRoomComponent extends Component {
    constructor(props) {
        super(props);
        // this.SERVER_URL = 'https://192.168.130.130:';
     
        this.hasBeenUpdated = false;
        this.layout = new OpenViduLayout();
        this.remotes = [];
        this.localUserAccessAllowed = true;
        this.state = {
            mySessionId: this.props.sessionId,
            myUserName: "test" + Math.floor(Math.random(1) * 100),
            session: undefined,
            localUser: undefined,
            subscribers: [],
            chatDisplay: 'none',
            currentVideoDevice: undefined,
            penStatus: false,
            rectStatus: false,
            rectTrackingStatus: false,
            serverToken: 111111,
            statusCode: 200,
            userScreenState: true
        };
        this.flag = true;
        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.updateLayout = this.updateLayout.bind(this);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.nicknameChanged = this.nicknameChanged.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.switchCamera = this.switchCamera.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.closeDialogExtension = this.closeDialogExtension.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.checkNotification = this.checkNotification.bind(this);
        this.checkSize = this.checkSize.bind(this);
        
    }

        unload =(event) => {
            
            // 显示确认对话框
            event.preventDefault();
            // 为了兼容处理，Chrome需要设置returnValue
            event.returnValue = '';
            this.leaveSession();     
            this.fetchMeeting()}

        fetchMeeting = ()=> {
            fetch("/manage/endMeeting", {
                method: 'Post',
                headers: {
                'Content-Security-Policy': 'upgrade-insecure-requests',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.authToken
                },
                body: JSON.stringify({
                    meetingRoom: this.state.mySessionId,
            })
                }).then(res=>res.json())
        
                .then(json=>{console.log(json)
                    if(json.code == "200"){
                    console.log("res ok")
                    }
                }).catch(error => console.log('Error! ' + error.message))
      }

     
    componentDidMount() {
        window.addEventListener('beforeunload',  this.unload);
        window.addEventListener('resize', this.updateLayout);
        window.addEventListener('resize', this.checkSize);

        const openViduLayoutOptions = {
            maxRatio: 2 / 3, // The narrowest ratio that will be used (default 2x3)
            minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
            fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
            bigClass: 'OV_big', // The class to add to elements that should be sized bigger
            bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
            bigFixedRatio: true, // fixedRatio for the big ones
            bigMaxRatio: 2 / 3, // The narrowest ratio to use for the big elements (default 2x3)
            bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
            bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
            animate: true, // Whether you want to animate the transitions
        };
        let token = window.name
        
        let index = token.indexOf("/")
        this.authToken = token.substring(0, index)
        this.sessionName = token.substring(index + 1)

        // let serverToken = "111111"
        //process.env.PUBLIC_URL = /192.168.130.130
        // fetch(this.SERVER_URL+ "443" + "/manage/meetingToken", {
        fetch("/manage/meetingToken", {
        method: 'Get',
        headers: {
        'Content-Security-Policy': 'upgrade-insecure-requests',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken
        },
          // body: JSON.stringify({
          //   parameterOne: 'something',
          //   parameterTwo: 'somethingElse'  
    // })
          }).then(res=>res.json())
  
          .then(json=>{console.log(json)
            if(json.code == "200"){
              console.log("res ok")
         
              this.setState({mySessionId: this.sessionName, 
                myUserName: json.data.username, statusCode: json.code, serverToken:json.data.meetingToken},()=>{
                this.layout.initLayoutContainer(document.getElementById('layout'), openViduLayoutOptions);
                console.log("===============status code===============>" + this.state.statusCode)    
                console.log("===============status code===============>" + this.state.serverToken)  
                console.log("===============status code===============>" + this.state.myUserName)  

                this.joinSession();
                })
            }
            else
            {
                this.setState({statusCode:json.code})
            }
          }).catch(error => console.log('Error! ' + error.message))

    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.unload);
        window.removeEventListener('resize', this.updateLayout);
        window.removeEventListener('resize', this.checkSize);
        this.fetchMeeting();
        this.leaveSession();     
    }

    joinSession() {
        this.OV = new OpenVidu();

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
     
                this.subscribeToStreamCreated();
                this.checkSomeoneShareScreen();
                this.connectToSession();
            },
        );
    }

    connectToSession() {

        if (this.props.token !== undefined) {
            console.log('token received: ', this.props.token);
            this.connect(this.props.token);
        } else {
            this.getToken().then((token) => {
                console.log(token);
                this.connect(token);
            }).catch((error) => {
                if(this.props.error){
                    this.props.error({ error: error.error, messgae: error.message, code: error.code, status: error.status });
                }
                console.log('There was an error getting the token:', error.code, error.message);
                alert('There was an error getting the token:', error.message);
              });
        }
    }

    connect(token) {
        this.state.session
            .connect(
                token,
                { clientData: this.state.myUserName },
            )
            .then(() => {
                this.connectWebCam();
            })
            .catch((error) => {
                if(this.props.error){
                    this.props.error({ error: error.error, messgae: error.message, code: error.code, status: error.status });
                }
                alert('There was an error connecting to the session:', error.message);
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    }

    async connectWebCam() {
        // const poster = require('../assets/images/poster.png');
        var defaultAudio = {deviceId: "13", kind: "audioinput", label: ""};
        var defaultVideo = {deviceId: "1312", kind: "videoinput", label: ""};
        var devices = await this.OV.getDevices();
        var videoDevices = devices?.filter(device => device.kind === 'videoinput');
        var audioDevices = devices?.filter(device => device.kind === 'audioinput');
        videoDevices.length !== 0? localUser.setVideoActive(true): localUser.setVideoActive(false);
        audioDevices.length !== 0? localUser.setAudioActive(true): localUser.setAudioActive(false);
        
   
            var publisher = this.OV.initPublisher(undefined, {
                audioSource: (audioDevices.length !== 0 && audioDevices[0].deviceId !== "")? audioDevices[0].deviceId: false,
                videoSource: videoDevices.length !== 0?  videoDevices[0].deviceId: false,
             
                publishAudio: localUser.isAudioActive(),
                // audioSource: audioDevices[0].deviceId,
                // videoSource: videoDevices[0].deviceId,
                // publishAudio: localUser.isAudioActive(),
                publishVideo: localUser.isVideoActive(),
                resolution: '1920x1080',
                frameRate: 30,
                insertMode: 'APPEND',
            });

    
        if (this.state.session.capabilities.publish) {
            publisher.on('accessAllowed' , () => {
                this.state.session.publish(publisher).then(() => {
                    this.updateSubscribers();
                    this.localUserAccessAllowed = true;
                    if (this.props.joinSession) {
                        this.props.joinSession();
                    }
                });
            });
        }
        localUser.setNickname(this.state.myUserName);
        localUser.setConnectionId(this.state.session.connection.connectionId);
        localUser.setScreenShareActive(false);
        localUser.setStreamManager(publisher);
        this.subscribeToUserChanged();
        this.subscribeToStreamDestroyed();
        this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });

        this.setState({ currentVideoDevice: videoDevices[0], localUser: localUser }, () => {
            this.state.localUser.getStreamManager().on('streamPlaying', (e) => {  
                this.updateLayout();
                publisher.videos[0].video.parentElement.classList.remove('custom-class');
            });
        });
    }

    updateSubscribers() {
        var subscribers = this.remotes;
        this.setState(
            {
                subscribers: subscribers,
            },
            () => {
                if (this.state.localUser) {
                    this.sendSignalUserChanged({
                        isAudioActive: this.state.localUser.isAudioActive(),
                        isVideoActive: this.state.localUser.isVideoActive(),
                        nickname: this.state.localUser.getNickname(),
                        isScreenShareActive: this.state.localUser.isScreenShareActive(),
                    });
                }
                this.updateLayout();
            },
        );
    }

    leaveSession() {
        const mySession = this.state.session;
        this.fetchMeeting()
        if (mySession) {
            mySession.disconnect();
        }

        // Empty all properties...
        this.OV = null;
        this.setState({
            session: undefined,
            subscribers: [],
            mySessionId: this.sessionName,
            myUserName: this.state.myUserName,
            localUser: undefined,
        });
        if (this.props.leaveSession) {
            this.props.leaveSession();
        }
    }
    camStatusChanged() {
        try {
            localUser.setVideoActive(!localUser.isVideoActive());
            localUser.getStreamManager().publishVideo(localUser.isVideoActive());
            this.sendSignalUserChanged({ isVideoActive: localUser.isVideoActive() });
            this.setState({ localUser: localUser });
        } catch (error) {
            window.confirm(
                'your cam seems not working'
            ) 
        }

    }

    micStatusChanged() {
        localUser.setAudioActive(!localUser.isAudioActive());
        localUser.getStreamManager().publishAudio(localUser.isAudioActive());
        this.sendSignalUserChanged({ isAudioActive: localUser.isAudioActive() });
        this.setState({ localUser: localUser });
    }

    nicknameChanged(nickname) {
        let localUser = this.state.localUser;
        localUser.setNickname(nickname);
        this.setState({ localUser: localUser });
        this.sendSignalUserChanged({ nickname: this.state.localUser.getNickname() });
    }

    deleteSubscriber(stream) {
        const remoteUsers = this.state.subscribers;
        const userStream = remoteUsers.filter((user) => user.getStreamManager().stream === stream)[0];
        let index = remoteUsers.indexOf(userStream, 0);
        if (index > -1) {
            remoteUsers.splice(index, 1);
            this.setState({
                subscribers: remoteUsers,
            });
        }
    }

     subscribeToStreamCreated= () => {
        
        this.state.session.on('streamCreated', (event) => {
            const subscriber = this.state.session.subscribe(event.stream, undefined);
            // var subscribers = this.state.subscribers;
            subscriber.on('streamPlaying', (e) => {
                this.checkSomeoneShareScreen();
                subscriber.videos[0].video.parentElement.classList.remove('custom-class');
            });
            const newUser = new UserModel();
            newUser.setStreamManager(subscriber);
            newUser.setConnectionId(event.stream.connection.connectionId);
            newUser.setType('remote');
            const nickname = event.stream.connection.data.split('%')[0];
            newUser.setNickname(JSON.parse(nickname).clientData);
            this.remotes.push(newUser);
            if(this.localUserAccessAllowed) {
                this.updateSubscribers();
            }
        });
    }

    subscribeToStreamDestroyed() {
        // On every Stream destroyed...
        this.state.session.on('streamDestroyed', (event) => {
            // Remove the stream from 'subscribers' array
            this.deleteSubscriber(event.stream);
            setTimeout(() => {
                this.checkSomeoneShareScreen();
            }, 20);
            event.preventDefault();
            this.updateLayout();
        });
    }

    subscribeToUserChanged() {

        this.state.session.on('signal:userChanged', (event) => {
            let remoteUsers = this.state.subscribers;
            remoteUsers.forEach((user) => {
                if (user.getConnectionId() === event.from.connectionId) {
                    const data = JSON.parse(event.data);
                    console.log('EVENTO REMOTE: ', event.data);
                    if (data.isAudioActive !== undefined) {
                        user.setAudioActive(data.isAudioActive);
                    }
                    if (data.isVideoActive !== undefined) {
                        user.setVideoActive(data.isVideoActive);
                    }
                    if (data.nickname !== undefined) {
                        user.setNickname(data.nickname);
                    }
                    if (data.isScreenShareActive !== undefined) {
                        user.setScreenShareActive(data.isScreenShareActive);
                    }
                }
            });
            this.setState(
                {
                    subscribers: remoteUsers,
                },
                () => this.checkSomeoneShareScreen(),
            );
        });
    }

    updateLayout() {
        if(this.state.statusCode == "200"){
        setTimeout(() => {
            this.layout.updateLayout();
        }, 20);
    }
    }

    sendSignalUserChanged(data) {
    
        const signalOptions = {
            data: JSON.stringify(data),
            type: 'userChanged',
        };
        this.state.session.signal(signalOptions);

    }

    toggleFullscreen() {
        const document = window.document;
        const fs = document.getElementById('container');
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (fs.requestFullscreen) {
                fs.requestFullscreen();
            } else if (fs.msRequestFullscreen) {
                fs.msRequestFullscreen();
            } else if (fs.mozRequestFullScreen) {
                fs.mozRequestFullScreen();
            } else if (fs.webkitRequestFullscreen) {
                fs.webkitRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    async switchCamera() {
        try{
            const devices = await this.OV.getDevices()
            var videoDevices = devices.filter(device => device.kind === 'videoinput');

            if(videoDevices && videoDevices.length > 1) {

                var newVideoDevice = videoDevices.filter(device => device.deviceId !== this.state.currentVideoDevice.deviceId)
                var audioDevices = devices.filter(device => device.kind === 'audioinput');

                if (newVideoDevice.length > 0) {
                    // Creating a new publisher with specific videoSource
                    // In mobile devices the default and first camera is the front one
                    var newPublisher = this.OV.initPublisher(undefined, {
                        audioSource: audioDevices[0].deviceId,
                        videoSource: newVideoDevice[0].deviceId,
                        publishAudio: localUser.isAudioActive(),
                        publishVideo: localUser.isVideoActive(),
                        mirror: true
                    });

                    //newPublisher.once("accessAllowed", () => {
                    await this.state.session.unpublish(this.state.localUser.getStreamManager());
                    await this.state.session.publish(newPublisher)
                    this.state.localUser.setStreamManager(newPublisher);
                    this.setState({
                        currentVideoDevice: newVideoDevice,
                        localUser: localUser,
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    screenShare() {
        const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
        // const videoSource = navigator.mediaDevices.getDisplayMedia();
        // debugger
        const publisher = this.OV.initPublisher(
            undefined,
            {
                videoSource: videoSource,
                publishAudio: localUser.isAudioActive(),
                publishVideo: localUser.isVideoActive(),
                mirror: false,
            },
            (error) => {
                if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
                    this.setState({ showExtensionDialog: true });
                } else if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
                    alert('Your browser does not support screen sharing');
                } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
                    alert('You need to enable screen sharing extension');
                } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
                    alert('You need to choose a window or application to share');
                }
            },
        );

        publisher.once('accessAllowed', () => {
            this.state.session.unpublish(localUser.getStreamManager());
            localUser.setStreamManager(publisher);
            this.state.session.publish(localUser.getStreamManager()).then(() => {
                localUser.setScreenShareActive(true);
                this.setState({ localUser: localUser }, () => {
                    this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });
                });
            });
        });
        publisher.on('streamPlaying', () => {
            this.updateLayout();
            publisher.videos[0].video.parentElement.classList.remove('custom-class');
        });
    }

    closeDialogExtension() {
        this.setState({ showExtensionDialog: false });
    }

    stopScreenShare() {
        this.state.session.unpublish(localUser.getStreamManager());
        this.connectWebCam();
        localUser.setBigClassActive(false);
    }

    checkSomeoneShareScreen() {
        let isScreenShared;
        // return true if at least one passes the test
        isScreenShared = this.state.subscribers.some((user) => user.isScreenShareActive()) || localUser.isScreenShareActive();
        const openviduLayoutOptions = {
            maxRatio: 2 / 3,
            minRatio: 9 / 16,
            fixedRatio: false,
            bigClass: 'OV_big',
            bigPercentage: 0.8,
            bigFixedRatio: false,
            bigMaxRatio: 2 / 3,
            bigMinRatio: 9 / 16,
            bigFirst: true,
            animate: true,
        };
        this.layout.setLayoutOptions(openviduLayoutOptions);
        this.updateLayout();
    }

    toggleChat(property) {
        let display = property;

        if (display === undefined) {
            display = this.state.chatDisplay === 'none' ? 'block' : 'none';
        }
        if (display === 'block') {
            this.setState({ chatDisplay: display, messageReceived: false });
        } else {
            console.log('chat', display);
            this.setState({ chatDisplay: display });
        }
        this.updateLayout();
        
    }

    checkNotification(event) {
        this.setState({
            messageReceived: this.state.chatDisplay === 'none',
        });
    }
    checkSize() {
        if (document.getElementById('layout').offsetWidth <= 700 && !this.hasBeenUpdated) {
            this.toggleChat('none');
            this.hasBeenUpdated = true;
        }
        if (document.getElementById('layout').offsetWidth > 700 && this.hasBeenUpdated) {
            this.hasBeenUpdated = false;
        }
    }

    getPenStatus = (penStatus, status) =>{
        const {rectStatus, rectTrackingStatus} = this.state
        this.setState({
            penStatus: status,
            rectStatus: penStatus? false: rectStatus,
            rectTrackingStatus: penStatus? false: rectTrackingStatus
        })
        this.updateLayout();
    }

    getRectStatus = (rectStatus, status) =>{
        const {penStatus, rectTrackingStatus} = this.state
        this.setState({
            rectStatus: status,
            penStatus: rectStatus? false: penStatus,
            rectTrackingStatus: rectStatus? false: rectTrackingStatus
        })
        this.updateLayout();
    }

    getRectTrackingStatus = (rectTrackingStatus, status) =>{
        const {penStatus, rectStatus} = this.state
        this.setState({
            rectTrackingStatus: status,
            penStatus: rectTrackingStatus? false: penStatus,
            rectStatus: rectTrackingStatus? false: rectStatus
        })
        this.updateLayout();
    }

    componentDidUpdate() {
    }

    checkNickUser = (sub) => {
      
        const pattern = /^device/i
        console.log("flag" + this.flag)

        // if(sub.screenShareActive && this.flag){
        //     sub.setBigClassActive(true)
        //     this.flag = false;
        //     // this.state.penStatus = false;
        //     return "OT_root OT_publisher OV_big"
        // }

        // if(pattern.test(sub.nickname) && this.flag){
        if(pattern.test(sub.nickname)){

            this.flag = false;
            sub.setAudioActive(true)
            sub.setVideoActive(true)
            sub.setBigClassActive(true)
            return "OT_root OT_publisher OV_big"
        }
        return "OT_root OT_subscriber custom-class"   
    }



    render() {
        
        this.flag = true;
        const mySessionId = this.state.mySessionId;
        const localUser = this.state.localUser;
        var chatDisplay = { display: this.state.chatDisplay };


        if(this.state.statusCode == "200"){
        return (
            <div className="container" id="container">
                <ToolbarComponent 
                    userCount = {this.state.subscribers.length}
                    sessionId={mySessionId}
                    user={localUser}
                    showNotification={this.state.messageReceived}
                    camStatusChanged={this.camStatusChanged}
                    micStatusChanged={this.micStatusChanged}
                    screenShare={this.screenShare}
                    stopScreenShare={this.stopScreenShare}
                    toggleFullscreen={this.toggleFullscreen}
                    switchCamera={this.switchCamera}
                    leaveSession={this.leaveSession}
                    toggleChat={this.toggleChat}
                    getPenStatus={this.getPenStatus}
                    getRectStatus={this.getRectStatus}
                    getRectTrackingStatus={this.getRectTrackingStatus}
                />

                <DialogExtensionComponent showDialog={this.state.showExtensionDialog} cancelClicked={this.closeDialogExtension} />

               
                <div id="layout" className="bounds">
                    {localUser !== undefined && localUser.getStreamManager() !== undefined && (
                        <div id="localUser" className={this.checkNickUser(localUser)}>
                            <StreamComponent user={localUser} handleNickname={this.nicknameChanged} />
                            {localUser.bigClassActive? <Drawing rectTrackingStatus={this.state.rectTrackingStatus}  penStatus={this.state.penStatus} rectStatus={this.state.rectStatus} user={localUser}/>:null}
                        </div>
                    )}


                    {this.state.subscribers.map((sub, i) => (
                        <div key={i} id="remoteUsers" className={this.checkNickUser(sub)} >
                            <StreamComponent user={sub} streamId={sub.streamManager.stream.streamId} />
                            {sub.bigClassActive? <Drawing rectTrackingStatus={this.state.rectTrackingStatus}  penStatus={this.state.penStatus} rectStatus={this.state.rectStatus} user={sub}/>:null}
                        </div>
                    ))}


           
                </div>

                {/* {localUser !== undefined && localUser.getStreamManager() !== undefined && (
                        <div className="chat" style={chatDisplay}>
                            <ChatComponent
                                user={localUser}
                                chatDisplay={this.state.chatDisplay}
                                close={this.toggleChat}
                                messageReceived={this.checkNotification}
                            />
                        </div>
                    )} */}
            </div>
        );}
        else {
            return(
                <Alert variant="outlined" severity="error">
                You do not have permission to access this page!
              </Alert>
            )
        }
    }

    getToken() {
        return this.createSession(this.state.mySessionId).then((sessionId) => this.createToken(sessionId));
    }

    createSession(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ customSessionId: sessionId });
            axios
                .post('/openvidu/api/sessions', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.state.serverToken),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('CREATE SESION', response);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    var error = Object.assign({}, response);
                    if (error.response && error.response.status === 409) {
                        resolve(sessionId);
                    } else {
                        console.log(error);
                        console.warn(
                            'No connection to  Server. This may be a certificate error',
                        );
                        if (
                            window.confirm(
                                'No connection to  Server. This may be a certificate error "' +
                                    '"\n\nClick OK to navigate and accept it. ' +
                                    'If no certificate warning is shown, then check that your Server is up and running "' 
                                    + '"',
                            )
                        ) {
                            window.location.assign("444" + '/accept-certificate');
                        }
                    }
                });
        });
    }

    createToken(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({});
            axios
                .post('/openvidu/api/sessions/' + sessionId + '/connection', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.state.serverToken),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data.token);
                })
                .catch((error) => reject(error));
        });
    }
}
export default VideoRoomComponent;
