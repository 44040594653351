import React, { Component } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

import GradientIcon from '@material-ui/icons/Gradient';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ColorSelect from './colors/ColorSelecting';
import LineWidth from './lineWidth/LineWidth';
import html2canvas from 'html2canvas';
import PubSub from 'pubsub-js'
import Crop169Icon from '@material-ui/icons/Crop169';
import PictureInPictureAltOutlinedIcon from '@material-ui/icons/PictureInPictureAltOutlined';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
const logo = require('../../assets/images/logo.png');

export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: false, isPenActive: false, isRectDrawing: false, isTrackingDrawing: false};
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.switchCamera = this.switchCamera.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.toggleChat = this.toggleChat.bind(this);

        this.drawing = this.drawing.bind(this);
        this.cleaning = this.cleaning.bind(this);
        this.snipping = this.snipping.bind(this);
        this.videoSetting = this.videoSetting.bind(this);
        this.rectDrawing = this.rectDrawing.bind(this);
        this.tracking_drawing = this.tracking_drawing.bind(this);
        
    }

    tracking_drawing() {
        const { isRectDrawing, isPenActive, isTrackingDrawing} = this.state;
        this.setState({
            isTrackingDrawing: !isTrackingDrawing,
            isPenActive: (!isRectDrawing || !isTrackingDrawing) ?false:isPenActive,
            isRectDrawing: (!isTrackingDrawing || !isPenActive) ?false:isRectDrawing,
        })
        console.log(isTrackingDrawing)
        this.props.getRectTrackingStatus(this, !this.state.isTrackingDrawing)
    }   


    rectDrawing() {
        const { isRectDrawing, isPenActive, isTrackingDrawing} = this.state;
        this.setState({
            isRectDrawing: !isRectDrawing,
            isPenActive: (!isRectDrawing || !isTrackingDrawing) ?false:isPenActive,
            isTrackingDrawing: (!isRectDrawing || !isPenActive) ?false:isTrackingDrawing,
        })
        this.props.getRectStatus(this, !this.state.isRectDrawing)
    }   

    drawing() {

        const { isRectDrawing, isPenActive, isTrackingDrawing} = this.state;
        console.log(isPenActive)
        this.setState({
            isPenActive: !isPenActive,
            isTrackingDrawing: (!isRectDrawing || !isPenActive) ?false:isTrackingDrawing,
            isRectDrawing: (!isTrackingDrawing || !isPenActive) ?false:isRectDrawing,
        })
        this.props.getPenStatus(this, !this.state.isPenActive)
    }

    cleaning() {
        PubSub.publish('cleaning', true);
    }

    snipping() {
        html2canvas(document.body, {
            allowTaint: false,
            useCORS: true,
        }).then(function (canvas) {
            const dataImg = new Image()
            dataImg.src = canvas.toDataURL('image/png')
            const alink = document.createElement("a");
            alink.href = dataImg.src;
            alink.download = "snapshot.jpg";
            alink.click();
        });
    }

    videoSetting() {
        this.props.videoSetting();
    }

    micStatusChanged() {
        try {
            this.props.micStatusChanged();
        } catch (error) {
            window.confirm(
                'your mic seems not working'
            )
        }
        
    }

    camStatusChanged() {
        try {
            this.props.camStatusChanged();
        } catch (error) {
            window.confirm(
                'your cam seems not working'
            )
        }
        
    }

    screenShare() {
        try {
            this.props.screenShare();
        } catch (error) {
            window.confirm(
                'something wrong, please exit and retry' 
            )
        }
        
    }

    stopScreenShare() {
        try {
            this.props.stopScreenShare(); 
        } catch (error) {
            window.confirm(
                'something wrong, please exit and retry' 
            )
        }
        
    }

    toggleFullscreen() {
        try {
            this.setState({ fullscreen: !this.state.fullscreen });
            this.props.toggleFullscreen();  
        } catch (error) {
            window.confirm(
                'something wrong, please exit and retry' 
            )
        }

    }

    switchCamera() {
        try {
            this.props.switchCamera();
        } catch (error) {
            window.confirm(
                'something wrong, please exit and retry' 
            ) 
        }
        
    }

    leaveSession() {
        try {
            this.props.leaveSession();
        } catch (error) {
            window.confirm(
                'something wrong, please exit and retry' 
            ) 
        }
        
    }

    toggleChat() {
        try {
            this.props.toggleChat();
        } catch (error) {
            window.confirm(
                'something wrong, please exit and retry' 
            ) 
        }
        
    }

    render() {
        const userCount  = this.props.userCount + 1;
       
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        return (
            <AppBar className="toolbar" id="header">
                <Toolbar className="toolbar">
                    <div id="navSessionInfo">
                        <img 
                            id="header_img"
                            alt="Expert Assistance"
                            src={logo}
                        />
                    </div>

              

                    <div className="buttonsContent ">
                        
                        <IconButton color="inherit" className="navButton " id="navMicButton" onClick={this.micStatusChanged}>
                        <Tooltip title="Mic">
                            {localUser !== undefined && localUser.isAudioActive() ? <Mic  color="#29a6ff"/> : <MicOff color="secondary" />}
                            </Tooltip>
                        </IconButton>
                        


                        <IconButton color="inherit" className="navButton" id="navCamButton" onClick={this.camStatusChanged}>
                        <Tooltip title="Cam">
                            {localUser !== undefined && localUser.isVideoActive() ? (
                                <Videocam titleAccess="Cam"/>
                            ) : (
                                <VideocamOff titleAccess="Cam Off" color="secondary" />
                            )}
                        </Tooltip>
                        </IconButton>
                        
                        <IconButton color="inherit" className="navButton" onClick={this.screenShare}>
                        <Tooltip title="Screen Share">
                            {localUser !== undefined && localUser.isScreenShareActive() ? <PictureInPicture /> : <ScreenShare />}
                            </Tooltip>
                        </IconButton>
                        


                        {localUser !== undefined &&
                            localUser.isScreenShareActive() && (
                                <IconButton onClick={this.stopScreenShare} id="navScreenButton">
                                    <StopScreenShare  color="secondary" />
                                </IconButton>
                            )}

                        {/* <IconButton color="inherit" className="navButton" onClick={this.switchCamera}>
                            <SwitchVideoIcon />
                        </IconButton> */}

                        <IconButton color="inherit" className="navButton" onClick={this.toggleFullscreen}>
                        <Tooltip title="Full Screen">
                            {localUser !== undefined && this.state.fullscreen ? <FullscreenExit /> : <Fullscreen />}
                            </Tooltip>
                        </IconButton>

                        <IconButton  color={this.state.isPenActive?"secondary":"inherit"} className="navButton">
                        <Tooltip title="Drawing">
                            <CreateOutlinedIcon  onClick={this.drawing}/>
                            </Tooltip>
                        </IconButton>        

                        <IconButton color={this.state.isRectDrawing?"secondary":"inherit"} className="navButton" onClick={this.rectDrawing}>
                        <Tooltip title="Rect Drawing">
                            <CropLandscapeOutlinedIcon />
                            </Tooltip>
                        </IconButton>
                  
                        <IconButton  color={this.state.isTrackingDrawing?"secondary":"inherit"} className="navButton" onClick={this.tracking_drawing}>
                        <Tooltip title="Tracking Drawing">
                            <PictureInPictureAltOutlinedIcon fontSize="small"/>
                            </Tooltip>
                        </IconButton>

                        <IconButton color="inherit" className="navButton" onClick={this.cleaning}>
                        <Tooltip title="Cleaning">
                            <GradientIcon />
                            </Tooltip>
                        </IconButton>
                        
                         
                        <IconButton color="inherit" className="navButton">
                        <Tooltip title="Line Width">
                            <LineWidth />
                            </Tooltip>
                        </IconButton>

                        <IconButton color="inherit" className="navButton">
                        <Tooltip title="Color Select">
                            <ColorSelect />
                            </Tooltip>
                        </IconButton>

                        <IconButton color="inherit" className="navButton" onClick={this.snipping}>
                        <Tooltip title="Screenshot">
                            <PhotoCameraIcon />
                        </Tooltip>
                        </IconButton>

                        <IconButton color="secondary" className="navButton" onClick={this.leaveSession} id="navLeaveButton">
                        <Tooltip title="Leave">
                            <PowerSettingsNew />
                        </Tooltip>
                        </IconButton>
        
                        {this.props.sessionId && 
                        <span id = "session-title" >{mySessionId}, current users：{userCount} </span>
                        }
                         {/* <IconButton color="inherit" onClick={this.toggleChat} id="navChatButton">
                            {this.props.showNotification && <div id="point" className="" />}
                            <Tooltip title="Chat">
                                <QuestionAnswer  titleAccess="Chat"/>
                            </Tooltip>
                        </IconButton> */}
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}
 