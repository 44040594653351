
import LineWeightIcon from '@material-ui/icons/LineWeight';
import PubSub from 'pubsub-js'
import LensIcon from '@material-ui/icons/Lens';

import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (width) => {
    PubSub.publish('lineWidth', width);
    console.log(width)
    setAnchorEl(null);
  };

  return (
    <div>
      <Button style = {{color: "#ffffff"}}  aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
      <Tooltip title="Line Width">
      <LineWeightIcon/>
      </Tooltip>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        style={{"top": "3.5%", zIndex: 999}}
        color = {"primary"}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem style = {{height:"15px", zIndex: 999}} onClick={() => handleClose(10)}><LensIcon style={{"width": "15px", "height": "5px", zIndex: 999}}/></MenuItem>
        <MenuItem style = {{height:"20px", zIndex: 999}} onClick={() => handleClose(15)}><LensIcon style={{"width": "15px", "height": "10px", zIndex: 999}}/></MenuItem>
        <MenuItem style = {{height:"25px", zIndex: 999}} onClick={() => handleClose(20)}><LensIcon style={{"width": "15px", "height": "15px", zIndex: 999}}/></MenuItem>
      </Menu>
    </div>
  )};