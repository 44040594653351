import React, { Component } from "react";
import { v4 } from "uuid";
import PubSub from "pubsub-js";
import "./Drawing.css";

export default class Drawing extends Component {
  constructor(props) {
    super(props);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.endPaintEvent = this.endPaintEvent.bind(this);
    this.state = {
      lineColor: "blue",
      lineWidth: 10,
      eraser: false,
      arrayVideo: [],
      rectDrawing: false,
    };
    this.startX = 0;
    this.startY = 0;
    this.endX = 0;
    this.endY = 0;
    this.lineWidth = 10;
    this.lineColor = "blue";
    this.rectCoordinate = [0, 0, 0, 0];
  }

  isPainting = false;
  line = [];
  userId = v4();
  prevPos = { offsetX: 0, offsetY: 0 };

  onMouseDown({ nativeEvent }) {
    const { offsetX, offsetY } = nativeEvent;
    this.startX = offsetX;
    this.startY = offsetY;
    this.isPainting = true;
    this.prevPos = { offsetX, offsetY };
  }

  onMouseMove({ nativeEvent }) {
    if (this.isPainting && this.lineWidth !== 0) {
      const { offsetX, offsetY } = nativeEvent;
      const offSetData = { offsetX, offsetY };
      this.position = {
        start: { ...this.prevPos },
        stop: { ...offSetData },
      };
      this.line = this.line.concat(this.position);
      this.lineColor =
        typeof this.lineColor === "string" ? this.lineColor : "red";
      console.log(typeof this.lineColor + this.lineColor);
      this.lineWidth = typeof this.lineWidth === "number" ? this.lineWidth : 10;
      if (this.props.penStatus) {
        this.paint(this.prevPos, offSetData, this.lineColor, this.lineWidth);
      }
      if (this.props.rectStatus|| this.props.rectTrackingStatus) {
        this.rectOffset(offSetData);
      }
    }
  }

  endPaintEvent(nativeEvent) {
  
    try {
      if (this.isPainting && this.lineWidth !== 0) {
        if (this.props.penStatus) {
          this.isPainting = false;
          this.sendPaintData();
          this.line = [];
        }
        if(this.props.rectStatus || this.props.rectTrackingStatus)
         {
          let width =
            this.endX - this.startX > 0
              ? this.endX - this.startX
              : this.startX - this.endX;
          let height =
            this.endY - this.startY > 0
              ? this.endY - this.startY
              : this.startY - this.endY;

          if (
            this.endX !== 0 &&
            this.startX < this.endX &&
            this.startY < this.endY &&
            this.endY !== 0 &&
            this.lineWidth !== 0
          ) {
            this.lineColor =
              typeof this.lineColor === "string" ? this.lineColor : "red";
            this.lineWidth =
              typeof this.lineWidth === "number" ? this.lineWidth : 10;
            
            this.paintRect(
              this.startX,
              this.startY,
              width,
              height,
              this.lineColor,
              this.lineWidth
            );
            console.log(this.startX, this.startY, this.endX, this.endY);
            this.isPainting = false;
            this.rectCoordinate = [
              this.startX,
              this.startY,
              this.endX,
              this.endY,
            ];
            const rectType = this.props.rectStatus? "rect": "rectTracking";
            this.sendPaintDataRect(rectType);
          }

          this.line = [];
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  paintRect(startX, startY, rectWidth, rectHeight, userStrokeStyle, lineWidth) {
    this.ctx.lineWidth = lineWidth;
    this.ctx.beginPath();
    this.ctx.strokeStyle = userStrokeStyle;
    this.ctx.rect(startX, startY, rectWidth, rectHeight);
    this.ctx.stroke();
    console.log(this.lineWidth + userStrokeStyle);
  }

  rectOffset(currPos) {
    const { offsetX, offsetY } = currPos;
    this.endX = offsetX;
    this.endY = offsetY;
  }

  paint(prevPos, currPos, strokeStyle, lineWidth) {
    const { offsetX, offsetY } = currPos;
    const { offsetX: x, offsetY: y } = prevPos;
    this.ctx.lineWidth = lineWidth;
    this.ctx.beginPath();
    this.ctx.strokeStyle = strokeStyle;
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(offsetX, offsetY);
    this.ctx.stroke();
    this.prevPos = { offsetX, offsetY };
    console.log(this.lineWidth);
  }

  async sendPaintDataRect(rectType) {
    const body = {
      rectType: rectType,
      rect: this.rectCoordinate,
      userId: this.userId,
      height: this.canvas.height,
      width: this.canvas.width,
      lineWidth: this.lineWidth,
      userStrokeStyle: this.state.lineColor,
    };
    console.log(body);
    try {
      await this.props.user.getStreamManager().stream.session.signal({
        data: JSON.stringify(body),
        type: "paintRect",
      });
    } catch (error) {
      console.log(error);
    }
  }

  async sendPaintData() {
    const body = {
      line: this.line,
      userId: this.userId,
      height: this.canvas.height,
      width: this.canvas.width,
      lineWidth: this.lineWidth,
      userStrokeStyle: this.state.lineColor,
    };
    console.log(this.props.user);

    try {
      await this.props.user.getStreamManager().stream.session.signal({
        data: JSON.stringify(body),
        type: "paint",
      });
    } catch (error) {
      console.log(error);
    }
  }

  dealLineData(heightRatio, widthRatio, guestLine, guestLineWidth, guestColor) {
    console.log(guestLine);
    let { start: startPosition } = guestLine[0];
    const { offsetX, offsetY } = startPosition;

    console.log(offsetX + "===" + offsetY);
    console.log(heightRatio + "-----===" + widthRatio);

    for (var i = 0; i < guestLine.length; i++) {
      let { start: startPosition } = guestLine[i];
      let { stop: stopPosition } = guestLine[i];
      console.log(
        "startPosition.offsetX:" +
        startPosition.offsetX +
        "=" +
        " startPosition.offsetY:" +
        startPosition.offsetY
      );
      startPosition.offsetX = parseInt(startPosition.offsetX * widthRatio);
      startPosition.offsetY = parseInt(startPosition.offsetY * heightRatio);
      stopPosition.offsetX = parseInt(stopPosition.offsetX * widthRatio);
      stopPosition.offsetY = parseInt(stopPosition.offsetY * heightRatio);
      console.log(
        "startPosition.offsetX:" +
        startPosition.offsetX +
        "=" +
        "startPosition.offsetY:" +
        startPosition.offsetY
      );
      this.paint(startPosition, stopPosition, guestColor, guestLineWidth);
    }
  }

  dealRectData(heightRatio, widthRatio, guestRect, guestLineWidth, guestColor) {
    let startX = parseInt(guestRect[0] * widthRatio);
    let startY = parseInt(guestRect[1] * heightRatio);
    let endX = parseInt(guestRect[2] * widthRatio);
    let endY = parseInt(guestRect[3] * heightRatio);

    let rectWidth = endX - startX > 0 ? endX - startX : startX - endX;
    let rectHeight = endY - startY > 0 ? endY - startY : startY - endY;
    this.paintRect(
      startX,
      startY,
      rectWidth,
      rectHeight,
      guestColor,
      guestLineWidth
    );
    console.log(
      startX,
      startY,
      rectWidth,
      rectHeight,
      guestLineWidth,
      guestColor
    );
  }

  componentDidUpdate() {
    const { lineWidth, lineColor, eraser, arrayVideo } = this.state;
    console.log(lineWidth, lineColor, eraser, arrayVideo);
    this.lineWidth = lineWidth;
    this.lineColor = lineColor;
    if (
      this.canvas.height !== arrayVideo[0] ||
      this.canvas.width !== arrayVideo[1]
    ) {
      this.canvas.height = arrayVideo[0];
      this.canvas.width = arrayVideo[1];
    }
    if (eraser) {
      this.canvas.height = arrayVideo[0];
      this.canvas.width = arrayVideo[1];
      this.state.eraser = false;
    }
  }

  componentDidMount() {
    this.props.user
      .getStreamManager()
      .stream.session.on("signal:eraser", (event) => {
        const guestData = JSON.parse(event.data);
        console.log("data2 is", guestData);
        this.setState({ eraser: guestData });
      });

    this.props.user
      .getStreamManager()
      .stream.session.on("signal:paint", (event) => {
        const guestData = JSON.parse(event.data);
        console.log("data2 is", guestData);
        if (guestData.userId != this.userId) {
          let guestHeight = guestData.height;
          let guestWidth = guestData.width;
          const { arrayVideo } = this.state;

          let guestLineWidth = guestData.lineWidth;
          let guestColor = guestData.userStrokeStyle;
          let guestLine = guestData.line;

          let heightRatio = arrayVideo[0] / guestHeight;
          let widthRatio = arrayVideo[1] / guestWidth;

          this.dealLineData(
            heightRatio,
            widthRatio,
            guestLine,
            guestLineWidth,
            guestColor
          );
        }
      });

    this.props.user
      .getStreamManager()
      .stream.session.on("signal:paintRect", (event) => {
        const guestData = JSON.parse(event.data);
        console.log("data3 is", guestData);
        debugger
        if (guestData.userId != this.userId && guestData.rectType == "rect") {
          let guestHeight = guestData.height;
          let guestWidth = guestData.width;
          const { arrayVideo } = this.state;

          let guestLineWidth = guestData.lineWidth;
          let guestColor = guestData.userStrokeStyle;

          let guestRect = guestData.rect;

          let heightRatio = arrayVideo[0] / guestHeight;
          let widthRatio = arrayVideo[1] / guestWidth;
          console.log("host width is guestRect" + guestRect);

          this.dealRectData(
            heightRatio,
            widthRatio,
            guestRect,
            guestLineWidth,
            guestColor
          );
        }
      });

    this.rectDrawing = false;
    this.userStrokeStyle = "red";
    this.canvas.background = "transparent";
    this.ctx = this.canvas.getContext("2d");
    this.ctx.lineJoin = "round";
    this.ctx.lineCap = "round";
    this.ctx.lineWidth = 5;

    PubSub.subscribe("lineWidth", (msg, data) => {
      console.log(msg, data);
      this.setState({ lineWidth: data });
    });
    PubSub.subscribe("lineColor", (msg, data) => {
      console.log(msg, data);
      this.setState({ lineColor: data, lineWidth: this.lineWidth });
    });
    PubSub.subscribe("cleaning", (msg, data) => {
      this.props.user.getStreamManager().stream.session.signal({
        data: JSON.stringify(data),
        type: "eraser",
      });
      this.setState({
        lineColor: this.state.lineColor,
        lineWidth: this.lineWidth,
        rectDrawing: this.rectDrawing,
      });
    });
    PubSub.subscribe("videoArray", (msg, data) => {
      console.log("videoArray is " + data);
      this.setState({ arrayVideo: data });
      console.log(msg, data);
    });
  }
  render() {
    return (
      <div>
        <canvas
          ref={(ref) => (this.canvas = ref)}
          // style={{ background: 'transparent', position: 'absolute', left:0, top:0, zIndex: 99}}
          style={{
            background: "transparent",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 99,
            display: "inline-block",
          }}
          onMouseDown={this.onMouseDown}
          onMouseLeave={this.endPaintEvent}
          onMouseUp={this.endPaintEvent}
          onMouseMove={this.onMouseMove}
        />
      </div>
    );
  }
}
